<template>
  <div class="warehous" v-loading="loading">
    <div class="census">
      <div class="item">
        <div class="title">订单合计</div>
        <div class="num">{{ census.total }}</div>
      </div>
      <div class="item">
        <div class="title">产品数量</div>
        <div class="num">{{ census.totalNum }}</div>
      </div>
    </div>
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" class="no-boder-select bdr" popper-class="no-border-select-popper"
                   v-model="formData.inStockId" @change="getList">
          <el-option v-for="item in sotckList" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
        </el-select>
        <el-button type="primary" @click="add">
          <div class="add_btn">
            <div class="icon">
              <el-icon>
                <Plus/>
              </el-icon>
            </div>
            <div class="add_text">{{ addBtnText }}</div>
          </div>
        </el-button>
      </div>
      <div class="queryItem">
        <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList"
            clearable
        />
        <el-select placeholder="请选择仓库" class="w200" v-model="formData.outStockId" @change="getList">
          <el-option v-for="item in sotckListMine" :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
        </el-select>
        <el-input
            v-elInput
            v-model="formData.query"
            class="w200"
            placeholder="产品名称/简称/条码"
            @keydown.enter="getList"
            clearable
        />
        <el-input
            v-elInput
            v-model="formData.transferNo"
            class="w200"
            placeholder="订单编号"
            @keydown.enter="getList"
            clearable
        />
        <el-select placeholder="请选择状态" class="w200" v-model="formData.status" @change="getList">
          <el-option label="全部状态" :value="0"/>
          <el-option label="待确认" :value="1"/>
          <el-option label="已撤销" :value="2"/>
          <el-option label="已拒绝" :value="3"/>
          <el-option label="进行中" :value="4"/>
          <el-option label="待入库" :value="5"/>
        </el-select>
        <div class="queryBtn">
          <el-button type="primary" @click="getList"
          >
            <el-icon>
              <Search/>
            </el-icon
            >
          </el-button>
          <el-button @click="fieldCheckShow">展示项设置</el-button>
        </div>
      </div>
    </div>
    <div class="tabList">
      <!-- 列表 -->
      <el-table
          :data="tableData"
          style="width: 100%"
          @row-click="checkGoods"
          height="100%"
          @sort-change="changeSort"
          class="zh-no-table-border"
          :row-class-name="tableRowClassName"
          :cell-style="{ border: 'none' }"
          :header-row-style="{ border: 'none' }"
      >
        <el-table-column
            type="index"
            width="100"
            label="序号"
            align="center"
            fixed
        />
        <template v-for="item in titleList" :key="item.fieldKey">
          <template v-if="item.isShow">
            <el-table-column
                v-if="item.fieldKey === 'productName'"
                label="产品名称"
                align="center"
                width="340"
            >
              <template #default="scope">
                <div class="nameBox">
                  <template
                      v-for="(item, index) in scope.row.productName"
                      :key="item"
                  >
                    <span class="name" v-if="index < 3"> {{ item }} </span>
                  </template>

                  <span class="more" v-if="scope.row.productName.length > 3"
                  >+{{ scope.row.productName.length - 3 }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'realityTotalPrice'"
                :prop="item.fieldKey"
                :label="item.title"
                :width="240"
                :sortable="item.sortable"
                :class-name="item.className || 'table_text2'"
                align="center"
            >
              <template #default="scope">
                <template v-if="scope.row.outCount">
                  {{ scope.row.outCount }} / {{ scope.row.outTotalPrice }}
                </template>
                <template v-else>--</template>
              </template>
            </el-table-column>
            <el-table-column
                v-else-if="item.fieldKey === 'statusDesc'"
                :prop="item.fieldKey"
                :label="item.title"
                :width="240"
                :sortable="item.sortable"
                :class-name="item.className || 'table_text2'"
                align="center"
            >
              <template #default="scope">
                <div class="status" :class="`status${scope.row.status}`">
                  {{ scope.row.statusDesc }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
                v-else
                align="center"
                :prop="item.fieldKey"
                :label="item.title"
                :width="item.width"
                :sortable="item.sortable"
                :class-name="item.className || 'table_text2'"
                show-overflow-tooltip
            >
            </el-table-column>
          </template>
        </template>
      </el-table>
    </div>
    <div class="mainPage">
      <el-pagination
          v-model:current-page="pageInfo.pageNumber"
          layout="total, prev, pager, next , jumper"
          :total="pageInfo.total"
          v-model:page-size="pageInfo.pageSize"
          @current-change="handleCurrentChange"
      />
    </div>
    <FieldsCheck
        :list="titleList"
        v-model="customFields"
        @close="checkFileds"
    />
    <!-- 订单详情 -->
    <OrderInfo
        v-model="orderShow"
        @close="closeDetail"
    ></OrderInfo>
  </div>
</template>
<script setup>
import {tableRowClassName} from "@/utils/tool.js";
import {computed, getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {useRoute} from "vue-router";
import {stockList} from '@/utils/api/procurement/stock.js'
import {getConfig} from '../uitl'
import {getDomHeight} from '@/utils/tool/getDomHeight.js'
import {transferListV2} from '@/utils/api/inbound/transferV2.js'
import {orderName} from '../orderConfig.js'
import {usePagination} from "@utils/tool/page";

const route = useRoute();
const {proxy} = getCurrentInstance();
const loading = ref(false);
const orderType = route.params.orderType || 0;
const isOrderType = computed(() => {
  return orderType === orderName.transferInStock
})
const sotckList = ref([])
const sotckListMine = ref([])
const {domHeight} = getDomHeight('.queryBoxOne');
//控组详情弹窗是否展示
const orderShow = ref(false);
//获取仓库列表
const getStockListData = async () => {
  const res = await stockList({})
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formData.inStockId = res.data.list[0].stockId
      sotckList.value = res.data.list
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      sotckList.value.push({stockId: "", stockName: '全部仓库'})
      sotckList.value.push(...res.data.list)
    }
    getList();
  } else {
    proxy.$message.error(res.msg)
  }
}

//获取仓库列表
const getStockListDataMine = async () => {
  const res = await stockList({
    all: true,
    status: false
  })
  let str = isOrderType.value ? '(调出)' : '(收货)'
  if (res.code == 0) {
    if (res.data?.list?.length == 1) {
      formData.outStockId = res.data.list[0].stockId
      res.data.list[0].stockName = str + res.data.list[0].stockName
      sotckListMine.value = res.data.list
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      res.data.list.forEach(item => {
        item.stockName = str + item.stockName
      })
      sotckListMine.value.push({stockId: "", stockName: str + '全部仓库'})
      sotckListMine.value.push(...res.data.list)
    }
    getList();
  } else {
    proxy.$message.error(res.msg)
  }
}
/*
  关于统计
*/
const census = reactive({
  total: 0,
  totalNum: 0,
  totalPrice: 0,
});
const formData = reactive({
  query: "",
  searchList: [],
  time: [],
  inStockId: "",
  outStockId: "",
  beginTime: "",
  endTime: "",
  transferNo: "",
  status: 0,
});
const {pageInfo} = usePagination()
//获取配置文件
const {titleList, titleListForCheck, addBtnText, searchModal} = getConfig(orderType)
const tableData = ref([]);
const customFields = ref(false);
const fieldCheckShow = () => {
  customFields.value = true;
};
const checkFileds = (list) => {
  customFields.value = false;
};
const getList = () => {
  tableData.value = [];
  loading.value = true;
  let beginTime = 0
  let endTime = 0
  if (formData.time && formData.time[0]) {
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if (formData.time && formData.time[1]) {
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  let obj = {
    beginTime: moment(beginTime).valueOf(),
    endTime: moment(endTime).valueOf(),
    query: formData.query,
    pageSize: pageInfo.pageSize,
    pageNumber: pageInfo.pageNumber,
    sortField: formData.sortField,
    sortType: formData.sortType,
    status: formData.status,
    stockId: formData.inStockId,
    otherStockId: formData.outStockId,
    transferNo: formData.transferNo,
    type: orderType,
  };
  transferListV2(obj)
      .then((res) => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg);
          return;
        }
        let list = res.data?.list || [];
        list.forEach((item) => {
          item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
          item.time = proxy.$filters.timeFormat(item.time, 1);
          if (item.productName) {
            item.productName = item.productName.split(",") || [];
          } else {
            item.productName = [];
          }
          item.inTotalPrice = proxy.$filters.toYuan(item.inTotalPrice, "元");
          item.outTotalPrice = proxy.$filters.toYuan(item.outTotalPrice, "元");
        });
        tableData.value = list;
        pageInfo.total = res?.data?.total || 0;
        census.total = res.data?.census?.total || 0;
        census.totalNum = res.data?.census?.totalNum || 0;
        census.totalPrice = res.data?.census?.totalPrice || 0;
        proxy.$emit("change");
      })
      .finally(() => {
        loading.value = false;
      });
};
const changeSort = ({column, prop, order}) => {
  if (!order) {
    formData.sortField = "";
    formData.sortType = "";
  } else {
    formData.sortField = prop;
    formData.sortType = order === "descending" ? "desc" : "asc";
  }
  getList();
};
//点击采购订单详情
const checkGoods = (e) => {
  let obj = {...e, orderType: orderType}
  proxy.$store.commit("PURCHASE_ORDER_INFO", obj);
  orderShow.value = true;
};
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
onMounted(() => {
  getStockListData()
  getStockListDataMine()
  checkOrder()
});
//判断store中的PURCHASE_ORDER_INFO是否有值
const checkOrder = () => {
  if (!proxy.$store.state.purchaseOrderInfo?.transferNo) return
  orderShow.value = true;
};
const closeDetail = () => {
  orderShow.value = false;
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  getList();
};
const add = () => {
  proxy.$emit("add");
};
</script>

<script>
import {Search, Plus, ArrowDown} from "@element-plus/icons-vue";
import {reactive, ref} from "vue";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import OrderInfo from "./orderInfo.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import moment from "moment";

export default {
  name: "warehous",
  components: {
    Search,
    Plus,
    FieldsCheck,
    OrderInfo,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.warehous {
  padding-top: 8px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;

  .tabList {
    margin-top: 20px;
    height: calc(100% - 120px - v-bind(domHeight));
  }

  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 14px;
      }

      .add_text {
        font-size: 14px;
        margin-left: 4px;
        height: 12px;
        line-height: 1;
        // position: relative;
        // top: 0.5px;
      }
    }
  }

  .imgList {
    img {
      width: 56px;
      height: 42px;
      border-radius: 8px;
      overflow: hidden;
    }

    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;

      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }

      .more {
        color: #73737f;
      }
    }

    .status {
      &.status1 {
        color: #FF910A
      }

      &.status2 {
        color: #AAAAB2
      }

      &.status3 {
        color: #FF6669
      }

      &.status4 {
        color: #3160F6
      }

      &.status5 {
        color: #3160F6
      }
    }
  }

  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
