<template>
  <div class="addAllocateOrder">
    <div class="content">
      <div class="queryBoxOne">
        <div class="btnBox">
          <div class="gyText"><span class="red">*</span>调出仓库</div>
          <el-select
              v-model="formData.outStockId"
              placeholder="请选择"
              class="w240"
              clearable
              @change='changeSearchList'
          >
            <el-option v-for="item in sotckList" :disabled="item.stockId === formData.inStockId" :key="item.stockId"
                       :label="item.stockName" :value="item.stockId"/>
          </el-select>
          <div class="gyText ml_16"><span class="red">*</span>收货仓库</div>
          <el-select
              v-model="formData.inStockId"
              placeholder="请选择"
              class="w240"
              clearable
              @change='changeSearchList'
          >
            <el-option v-for="item in sotckListMine" :disabled="item.stockId === formData.outStockId"
                       :key="item.stockId" :label="item.stockName" :value="item.stockId"/>
          </el-select>
        </div>
        <div class="queryItem">
          <div class="queryBtn animate__animated animate__zoomIn" v-show='isShowGoods'>
            <el-button @click="openAdd" type="primary">批量添加产品</el-button>
          </div>
        </div>
      </div>
      <div class="census animate__animated animate__zoomIn" v-show='isShowGoods'>
        <div class="item">
          <span class="text">计划数量：</span>
          <span class="num">{{ getSum.total }}</span>
        </div>
      </div>
      <div class="tabList" v-show='isShowGoods'>
        <el-table :data="tableData" style="width: 100%" height="100%">
          <el-table-column label="序号" width="100" align="center" fixed>
            <template #default="scope">
              <div class="table_text">
                {{
                  (pageInfo.pageNumber - 1) * pageInfo.pageSize +
                  scope.$index +
                  1
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="254" align="center" fixed>
            <template #header>
              <div><span class="red">*</span> 产品名称</div>
            </template>
            <template #default="scope">
              <div class="table_text2 tableName160" v-if="scope.row.productId">
                {{ scope.row.productName }}
              </div>
              <SelectGoodsFromList
                  :propList='tableData'
                  v-model:goods="tableData[scope.$index]"
                  @change="pushGoods"
                  :stockId='formData.outStockId'
                  :order-type="orderType === '2' ? '4' : '8'"
                  v-else
              ></SelectGoodsFromList>
            </template>
          </el-table-column>
          <el-table-column width="180" align="center" label="简称" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.subName }}
            </template>
          </el-table-column>
          <el-table-column
              width="120"
              align="center"
              label="规格/型号"
              show-overflow-tooltip
              class-name="table_text2"
          >
            <template #default="scope">{{ scope.row.modelName }}
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" label="产品品牌" show-overflow-tooltip>
            <template #default="scope">
              <template v-if="scope.row.productId">
                  {{ scope.row.brandName || '--' }}
              </template>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" label="产品单位" show-overflow-tooltip>
            <template #default="scope">
              <div
                  class="table_text2"
                  v-html="getUnitName(scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column width="200" align="center" class-name="table_text2" label="可用库存" show-overflow-tooltip>
            <template #default="scope">
              {{ getStockCount(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column width="160" align="center">
            <template #header>
              <div><span class="red">*</span> 计划数量</div>
            </template>
            <template #default="scope">
              <el-input
                  v-elInput
                  v-number
                  class="name tablInput w96"
                  :class="[scope.row.countErr ? 'err' : '']"
                  placeholder="计划数量"
                  v-model="scope.row.count"
                  @blur="scope.row.count = $event.target.value"
                  v-if="scope.row.productId && !scope.row.scattered"
              />
              <el-input
                  v-elInput
                  v-number.fixed
                  class="name tablInput w96"
                  :class="[scope.row.countErr ? 'err' : '']"
                  placeholder="计划数量"
                  v-model="scope.row.count"
                  @blur="scope.row.count = $event.target.value"
                  v-if="scope.row.productId && scope.row.scattered"
              />
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <div class="contBtn" @click='delGoods(scope.$index)' v-if="scope.$index !== tableData.length - 1">
                移除
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="remark">
        <div class="title">消息</div>
        <div class="input">
          <el-input
              v-elInput
              v-model="remark"
              type="textarea"
              placeholder="输入信息"
              class="textareaInput"
              input-style="border: none;box-shadow:none"
              resize="none"
              maxlength="100"
          />
        </div>
        <div class="btnBox">
          <el-button class="btn1 zh-btn w160" @click="cancel">取消</el-button>
          <el-button class="main btn1 w160" type="primary" @click="confirm">提交</el-button>
        </div>
      </div>
    </div>
    <Bind v-model="bindDia" title='批量添加' :stockId='formData.outStockId' :propList='tableData' @close='closeAdd'
          @save='moreAddOk'></Bind>
  </div>
</template>
<script setup>
import Bind from './component/bind.vue';
import {ElMessageBox} from "element-plus";
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import {stockList} from "@/utils/api/procurement/stock.js";
import {cloneDeep} from 'lodash';
import {useRoute, useRouter} from "vue-router";
import {transferAddV2} from '@/utils/api/inbound/transferV2.js';
import {getStockCount, getUnitName} from "@utils/tool/orderTools";

const router = useRouter();
const route = useRoute();
const orderType = route.params.orderType || 0;
const {proxy} = getCurrentInstance();
const formData = reactive({
  inStockId: '',
  outStockId: '',
});

const tableData = ref([{}])

const remark = ref('')
const bindDia = ref(false)
//判断orderType 是否为2

const isOrderType = computed(() => {
  return orderType === "4" || orderType === "2"
})

//打开批量添加弹窗
const openAdd = () => {
  bindDia.value = true
}

//保存批量添加
const moreAddOk = (e) => {
  tableData.value.pop()
  tableData.value = tableData.value.concat(e)
  tableData.value.push({})
}

const closeAdd = () => {
  bindDia.value = false
}

const pushGoods = (e) => {
  let index = tableData.value.findIndex((item) => item.productId == e.productId);
  if (index != -1) {
    e.brandName = tableData.value[index].brandName
    e.brand = tableData.value[index].brand
    e.brandId = tableData.value[index].brandId
  }
  tableData.value.push({});
};
const pageInfo = reactive({
  pageNumber: 1,
  pageSize: 10,
});
const sotckList = ref([])

//获取仓库列表
const getSotckList = () => {
  stockList({
    status: true,
    all: true,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    if (isOrderType.value) {
      sotckList.value = res.data.list || [];
    } else {
      sotckListMine.value = res.data.list || []
    }
  });
};
const sotckListMine = ref([])

//获取仓库列表
const getStockListDataMine = async () => {
  const res = await stockList({
    status: true,
    all: false,
  })
  if (res.code == 0) {
    if (isOrderType.value) {
      sotckListMine.value = res.data.list || []
    } else {
      sotckList.value = res.data.list || [];
    }
  } else {
    proxy.$message.error(res.msg)
  }
}


//机选总金额
const getSum = computed(() => {
  let total = 0;
  tableData.value.forEach((item) => {
    if (item.productId) {
      total += Number(item.count)
    }
  });
  return {
    total,
  };
});


//清空数据
const clearData = () => {
  tableData.value = [{}];
  remark.value = ''
};

const changeSearchList = () => {
  clearData()
}


//移除
const delGoods = (index) => {
  tableData.value.splice(index, 1);
};


//取消
const cancel = () => {
  ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    proxy.$emit('close')
  })
};
let timer = null

//确认
const confirm = () => {
  if (formData.inStockId == formData.outStockId) {
    proxy.$message.error('请选择不同的仓库')
    return
  }
  if (tableData.value.length == 1) {
    proxy.$message.error('请先添加产品')
    return
  }
  let list2 = tableData.value
  list2.forEach((item, index) => {
    if (index < (list2.length - 1)) {
      item.countErr = !item.count || item.count == 0;
    }
  })
  let list = cloneDeep(list2)
  list.pop()
  //判断是否数量有空值
  if (list.length > 0) {
    if (list.some((item) => item.countErr)) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        list2.forEach((item) => {
          item.countErr = false
        })
      }, 1000)
      proxy.$message.error('计划数量不能为0')
      return
    }
  }
  transferAddV2({
    outStockId: formData.outStockId,
    inStockId: formData.inStockId,
    remark: remark.value,
    list: list,
    type: orderType,
  }).then((res) => {
    if (res.code === 0) {
      proxy.$message.success("订单添加成功")
      proxy.$emit('close')
    } else {
      proxy.$message.error(res.msg)
    }
  });
};


//是否显示商品或者批量添加
const isShowGoods = computed(() => {
  return formData.inStockId && formData.outStockId
})


onMounted(() => {
  getSotckList()
  getStockListDataMine()
})
</script>

<script>
import {ArrowDown, Search} from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";

export default {
  name: "addOrder",
  components: {
    Search,
    ArrowDown,
    SelectGoodsFromList,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss">
@import "./index.scss";

:deep(.el-table__row) {
  cursor: pointer;
}
</style>
