<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
      v-bind="attrs"
      title="编辑订单"
      width="1400"
      class="goodInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @open="openDetail"
  >
    <div class="zh_content">
      <div class="info">
        <div class="zh_title">基本信息</div>
        <div class="zh_introduce">
          <div class="item">
            <div class="text">调拨订单：</div>
            <div class="desc">{{ orderInfo.transferNo }}</div>
          </div>
          <div class="item">
            <div class="text">调出仓库：</div>
            <div class="desc">{{ orderInfo.outStockName }}</div>
          </div>
          <div class="item">
            <div class="text">收货仓库：</div>
            <div class="desc">{{ orderInfo.inStockName }}</div>
          </div>
          <div class="item" v-if="orderInfo.orderStatusDesc">
            <div class="text">状态：</div>
            <div class="desc">{{ orderInfo.orderStatusDesc }}</div>
          </div>
        </div>
        <div class="mt32 dp_f">
          <div class="zh_title">产品信息</div>
          <el-button type="primary" @click="openAdd">批量添加</el-button>
        </div>

        <div class="zh_introduce">
          <div class="item">
            <div class="text">产品数量：</div>
            <div class="desc">{{ goodsNum.count }}</div>
          </div>
        </div>
        <div class="tabList">
          <el-table :data="tableData" style="width: 100%" height="100%">
            <el-table-column
                type="index"
                width="100"
                label="序号"
                align="center"
                fixed
            />
            <el-table-column width="254" align="center">
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                <div
                    v-if="scope.row.productName"
                    class="table_text2 tableName160"
                >
                  {{ scope.row.productName }}
                </div>
                <SelectGoodsFromList
                    v-model:goods="tableData[scope.$index]"
                    :propList="tableData"
                    @change="pushGoods"
                    :stockId="orderInfo.outStockId"
                    :order-type="orderType === '2' ? '4' : '8'"
                    v-else
                ></SelectGoodsFromList>
              </template>
            </el-table-column>
            <el-table-column
                width="130"
                align="center"
                class-name="table_text2"
                label="简称"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.subName || "" }}
              </template>
            </el-table-column>
            <el-table-column
                width="130"
                align="center"
                label="产品品牌"
                show-overflow-tooltip
                class-name="table_text"
            >
              <template #default="scope">
                {{ scope.row.brandName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                align="center"
                label="产品单位"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text2" v-html="getUnitName(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column
                width="200"
                align="center"
                label="规格型号"
                show-overflow-tooltip
                class-name="table_text2"
            >
              <template #default="scope">
                {{ scope.row.modelName || "" }}
              </template>
            </el-table-column>
            <el-table-column width="140" align="center">
              <template #header>
                <div>计划数量</div>
              </template>
              <template #default="scope">
                <el-input
                    v-elInput
                    class="name tablInput w96"
                    :class="[scope.row.countErr ? 'err' : '']"
                    placeholder="输入数量"
                    maxlength="5"
                    v-model="scope.row.count"
                    v-number
                    v-if="scope.row.productId"
                    @blur="
                    ($event) => {
                      setNum($event, scope.$index);
                    }
                  "
                />
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
              <template #default="scope">
                <span
                    class="contBtn mr_10"
                    v-if="scope.$index != tableData.length - 1"
                    @click="delItem(scope.$index)"
                >移除</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="remark">
          <div class="btnBox">
            <el-button class="btn1 info_btn zh-btn" @click="close"
            >取消
            </el-button
            >
            <el-button type="primary" class="btn1 info_btn" @click="handleEdit"
            >保存
            </el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <Bind
      v-model="bindDia"
      title="批量添加"
      :propList="tableData"
      :stockId="orderInfo.outStockId"
      @close="closeAdd"
      @save="moreAddOk"
  ></Bind>
</template>
<script setup>
import Bind from "../addOrder/component/bind.vue";
import {transferEditV2, transferGoodsListV2} from "@/utils/api/inbound/transferV2.js";
import {computed, getCurrentInstance, reactive, ref, toRefs, useAttrs, watch,} from "vue";
import {useStore} from "vuex";
import {ElMessageBox} from "element-plus";
import {useRoute} from "vue-router";
import {cloneDeep} from "lodash";
import {orderName} from '../orderConfig'
import {resetGoodsList} from "@utils/tool";
import {getUnitName} from "@utils/tool/orderTools";

const attrs = useAttrs();
const {proxy} = getCurrentInstance();
const store = useStore();
const bindDia = ref(false);
const route = useRoute();
const orderType = ref('');
const isOrderType = computed(() => {
  return orderType.value === orderName.transferInStock
})
//获取配置文件
const closeAdd = () => {
  bindDia.value = false;
};
//打开批量添加弹窗
const openAdd = () => {
  bindDia.value = true;
};
//保存批量添加
const moreAddOk = (e) => {
  tableData.value.pop();
  tableData.value = tableData.value.concat(e);
  tableData.value.push({});
};

const orderInfo = reactive({
  transferNo: "",
  supplierName: "",
  productName: [],
  createTime: "",
  createUserName: "",
  remark: "",
  orderStatus: 1,
  outStockId: "",
  outStockName: "",
  inStockId: "",
  inStockName: "",
});
const goodsNum = computed(() => {
  let count = 0;
  let totalPrice = 0;
  tableData.value.forEach((item) => {
    count += Number(item.count) || 0;
    totalPrice += Number(item.totalPrice) || 0;
  });
  return {
    count : Number.isInteger(count) ? count : count.toFixed(2),
    totalPrice : Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2),
  };
});
const tableData = ref([{}]);
const goodsList = ref([]);
//确认是否可提交
const canConfirm = computed(() => {
  return (
      tableData.value.every((item, index) => {
        //判断item.count非空不等于0
        if (index === tableData.value.length - 1) return true;
        return (
            item.productId &&
            item.count &&
            item.count != 0 &&
            item.price != undefined
        );
      }) && tableData.value.length > 1
  );
});
const openDetail = () => {
  reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
  orderType.value = orderInfo.orderType || route.params.orderType || 0
  getGoodsList();
};
//设置数量
const setNum = (e, index) => {
  tableData.value[index].count = e.target.value || 0;
};
//获取产品列表
const getGoodsList = () => {
  transferGoodsListV2({
    transferNo: orderInfo.transferNo,
    orderStatus: 0,
    type: orderType.value,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data.list || []
    tableData.value = resetGoodsList(list);
    tableData.value.push({});
  });
};
const pushGoods = () => {
  tableData.value.push({});
};
//移除
const delItem = (index) => {
  tableData.value.splice(index, 1);
};
let timer = null;
//确认编辑
const handleEdit = () => {
  if (tableData.value.length == 1) {
    proxy.$message.error('订单内最少添加一个产品')
    return
  }
  let list2 = tableData.value
  list2.forEach((item, index) => {
    if (index < (list2.length - 1)) {
      if (!item.count || item.count == 0) {
        item.countErr = true
      } else {
        item.countErr = false
      }
    }
  })
  let list = cloneDeep(list2)
  list.pop()
  //判断是否数量有空值
  if (list.length > 0) {
    if (list.some((item) => item.countErr)) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        list2.forEach((item) => {
          item.countErr = false
        })
      }, 1000)
      proxy.$message.error('计划数量不能为0')
      return
    }
  }
  //判断是否数量有空值
  transferEditV2({
    transferNo: orderInfo.transferNo,
    remark: orderInfo.remark,
    list: list,
    type: orderType.value,
    thirdId: isOrderType.value ? orderInfo.outStockId : orderInfo.inStockId,
    stockId: isOrderType.value ? orderInfo.inStockId : orderInfo.outStockId,
    thirdName: orderInfo.thirdName,
  }).then((res) => {
    if (res.code === 6999) {
      proxy.$message.error(res.msg);
      return
    }
    if (res.code != 0 && res.code != 6999) {
      ElMessageBox.confirm(res.msg, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        customClass: "zh-message-box",
      }).then(() => {
        proxy.$emit("closeAll");
      });
      return;
    }
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      close()
      return;
    }
    proxy.$message.success("修改成功");
    store.commit("PURCHASE_ORDER_INFO", orderInfo);
    close()
  });
};
const close = () => {
  proxy.$emit("closeDia");
};
</script>

<script>
import {reactive, toRefs, ref, watch, getCurrentInstance} from "vue";
import {reactiveAssign} from "@/utils/tool.js";
import {ArrowDown} from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";

export default {
  components: {
    ArrowDown,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 840px;
    box-sizing: border-box;
    position: relative;

    .zh_introduce {
      &.status3 {
        flex-wrap: wrap;
        height: 80px;
        padding: 15px;
        box-sizing: border-box;

        .item {
          width: 33%;
          @include textOverflow(33%);
          margin-bottom: 15px;
        }
      }
    }

    .tabList {
      height: 500px;
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;

      .name {
        color: #73737f;
      }

      .text2 {
        color: $fsColor;
      }

      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
      }

      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }

    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;

      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }

      .remark_text {
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }

      .input {
        margin-top: 16px;
      }

      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;

        .info_btn {
          width: 112px;
        }

        .select {
          margin: 0 8px;
          width: 160px;
        }
      }
    }
  }
}

:deep(.el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
