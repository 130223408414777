<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
      v-bind="$attrs"
      title="调拨订单"
      width="1400"
      class="goodInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @open="openDetail"
      @close="close"
  >
    <div class="zh_content">
      <div class="info">
        <div class="dp_f">
          <div class="zh_title">基本信息</div>
          <div class="btn-box">
            <el-button type="primary" @click="printHmtl">打印</el-button>
          </div>
        </div>
        <div class="zh_introduce">
          <div class="item">
            <div class="text">订单编号：</div>
            <div class="desc">{{ orderInfo.transferNo }}</div>
          </div>
          <div class="item">
            <div class="text">调出仓库：</div>
            <div class="desc">{{ orderInfo.outStockName }}</div>
          </div>
          <div class="item">
            <div class="text">收货仓库：</div>
            <div class="desc">{{ orderInfo.inStockName }}</div>
          </div>
          <div class="item">
            <div class="text">状态：</div>
            <div class="desc">{{ orderInfo.statusDesc }}</div>
          </div>
        </div>
        <div class="zh_title mt32">消息</div>
        <div class="zh-message mt_16">
          <el-carousel
              height="48px"
              direction="vertical"
              :autoplay="true"
              indicator-position="none"
              :pause-on-hover="false"
          >
            <el-carousel-item v-for="item in chatList" :key="item.time">
              <div class="mes-box">
                <div class="time">{{ $filters.timeFormat(item.time, 1) }}</div>
                <div class="mes">
                  <img :src="item.avatar" class="ava" alt=""/>
                  <div class="nickName">{{ item.nickName }}：</div>
                  <div class="mes-text">{{ item.text }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <img
              class="send-mes cur_p"
              src="../../../../assets/img/inbound/mes.png"
              @click="handleChat"
              alt=""
          />
        </div>
        <div class="zh_title mt32">产品信息</div>
        <div class="zh_introduce">
          <div class="item">
            <div class="text">产品数量：</div>
            <div class="desc">{{ goodsNum.count }}</div>
          </div>
          <!-- <div class="item" v-if=" orderType == 4 && orderInfo.active && orderInfo.orderStatus == 14">
            <div class="text">{{orderType == 4 ?'入库':'出库'}}合计金额(元)：</div>
            <div class="desc">{{goodsNum.totalPrice}}</div>
          </div> -->
        </div>
        <div class="tabList">
          <el-table
              :data="tableData"
              style="width: 100%"
              height="100%"
              class="zh-no-table-border"
              :row-class-name="tableRowClassNameNew"
              :cell-style="{ border: 'none' }"
              :span-method="objectSpanMethod([1,2,3,4])"
              :header-row-style="{ border: 'none' }"
          >
            <el-table-column
                type="index"
                width="100"
                label="序号"
                align="center"
                fixed
            />
            <el-table-column
                align="center"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #header>
                <div>产品名称</div>
              </template>
              <template #default="scope">
                {{ scope.row.productName }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="简称"
                width="150"
                class-name="table_text2"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.subName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="150"
                class-name="table_text"
                label="产品品牌"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.brandName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                class-name="table_text2"
                label="规格/型号"
                show-overflow-tooltip
            >
              <template #default="scope">
                {{ scope.row.modelName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                align="center"
                class-name="table_text2"
                show-overflow-tooltip
                label="产品单位"
            >
              <template #default="scope">
                <div
                    v-html="getUnitName(scope.row)">
                </div>
              </template>
            </el-table-column>
            <el-table-column width="150" align="center">
              <template #header>
                <div>计划数量</div>
              </template>
              <template #default="scope">
                <div class="table_text2">
                  {{ scope.row.count }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
                width="190"
                align="center"
                label="出库数量/金额（元）"
                v-if="!orderInfo.active && orderInfo.status === 14"
            >
              <template #default="scope">
                <div class="table_dp_item">
                  <span class="table_text2">{{ scope.row.outCount || 0 }}</span>
                  <span class="unit">/</span
                  ><span class="table_text2">
                    {{ scope.row.outTotalPrice }}</span
                ><span
                    class="contBtn"
                    @click="showRfid(scope.row, 8)"
                    v-if="scope.row.outCount > 0"
                >查看明细</span
                >
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column width="190" align="center" label="入库数量/金额（元）"  v-if='orderType == 4 && orderInfo.orderStatus == 1'>
              <template #default="scope">
                <div class="table_dp_item">
                  <span class="table_text2">{{scope.row.outCount || 0}}</span> <span class="unit">/</span><span class="table_text2"> {{'￥' + scope.row.inTotalPrice}}</span><span class="contBtn" @click="showRfid(scope.row,4)" v-if="scope.row.outCount > 0">查看明细</span>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <div class="remark">
          <div
              class="btnBox"
              v-if="isWaitStatus"
          >
            <el-button
                class="btn1 info_btn"
                type="primary"
                @click="cancelOrder(2)"
            >撤销
            </el-button
            >
          </div>
          <div
              class="btnBox"
              v-else-if="isCancelStatus || isRefuseStatus"
          >
            <el-button class="btn1 zh-btn info_btn" @click="delOrder"
            >删除
            </el-button
            >
            <el-button
                class="btn1 info_btn"
                type="primary"
                @click="clickChange"
            >编辑
            </el-button
            >
          </div>
          <div
              class="btnBox"
              v-else-if="isDoingStatus"
          >
            <el-button
                class="btn1 info_btn"
                type="primary"
                @click="cancelOrder(2)"
            >撤销
            </el-button
            >
          </div>
          <div
              class="btnBox"
              v-else-if="isWaitStatusNotActive"
          >
            <el-button class="btn1 zh-btn info_btn" @click="cancelOrder(3)"
            >拒绝调{{ confirmBtnText }}
            </el-button
            >
            <el-button
                class="btn1 info_btn"
                type="primary"
                @click="upDateOrder(4)"
            >同意调{{ confirmBtnText }}
            </el-button
            >
          </div>
          <div class="btnBox" v-else></div>
        </div>
      </div>
    </div>
  </el-dialog>
  <ChangeOrder v-model="changeShow" @closeDia="openDetail" @closeAll="closeAll"></ChangeOrder>
  <Chat v-model="chatShow" :orderInfo="orderInfo"></Chat>
  <RfidList
      v-model="rfidShow"
      :oderStauts="chooseOrderStauts"
      v-model:goodsInfo="goodsInfo"
  ></RfidList>
  <el-dialog
      v-model="cancelOrderShow"
      :title="diaTitle"
      width="480"
      class="error-reason"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      :show-close="false"
  >
    <div class="zh_content">
      <div class="zh-input">
        <el-input
            v-elInput
            v-model="remark"
            type="textarea"
            placeholder="请输入原因"
            class="textareaInput"
            resize="none"
            maxlength="100"
            show-word-limit
            input-style="border: none;box-shadow:none"
        ></el-input>
      </div>
    </div>
    <template #footer>
      <el-button @click="cancelOrderShow = false" class="btn1 zh-btn"
      >取消
      </el-button
      >
      <el-button
          class="main btn1"
          type="primary"
          @click="upDateOrder(cancelValue)"
      >保存
      </el-button
      >
    </template>
  </el-dialog>
</template>
<script setup>
import ChangeOrder from "./changeOrder.vue";
import {objectSpanMethod, resetGoodsList, tableRowClassNameNew} from "@/utils/tool.js";
import Chat from "./chat.vue";
import {productInfo} from "@/utils/api/product/product.js";
import {computed, getCurrentInstance, reactive, ref} from "vue";
import {useStore} from "vuex";
import {ElMessageBox} from "element-plus";
import {useRoute} from "vue-router";
import {orderMsg} from "@/utils/api/chat.js";
import {getUnitName} from "@utils/tool/orderTools";

import {transferDeleteV2, transferDetailV2, transferExamineV2, transferGoodsListV2} from "@utils/api/inbound/transferV2"

const {proxy} = getCurrentInstance();
const route = useRoute();
const emit = defineEmits(["close"]);
const store = useStore();
const changeShow = ref(false);
const addShow = ref(false);
const orderType = ref('');
const chatShow = ref(false);
//获取配置文件
const chatList = ref([]);
const rfidShow = ref(false);
const goodsInfo = reactive({
  transferNo: "",
  productId: "",
  modelId: "",
  productName: "",
  encode: [],
  unitName: "",
  modelName: "",
  checkPrice: "",
  brandName: "",
  checkCount: "",
  subName: "",
  price: "",
  img: "",
});

//编辑
const clickChange = () => {
  changeShow.value = true;
};

//判断orderType是否为4或者2
const isOrderType = computed(() => {
  return orderType.value === "4" || orderType.value === "2"
})

const confirmBtnText = computed(() => {
  return orderType.value === '1' ? "出" : "入"
})

//判断待确认状态 并且主动方
const isWaitStatus = computed(() => {
  return orderInfo.status === 1 && orderInfo.active
})

//判断已撤销状态 并且主动方
const isCancelStatus = computed(() => {
  return orderInfo.status === 2 && orderInfo.active
})

//判断已拒绝状态 并且主动方
const isRefuseStatus = computed(() => {
  return orderInfo.status === 3 && orderInfo.active
})

//判断进行中状态。并且主动方
const isDoingStatus = computed(() => {
  return orderInfo.status === 4 && orderInfo.active
})

//判断待确认 并且非主动方
const isWaitStatusNotActive = computed(() => {
  return orderInfo.status === 1 && !orderInfo.active && orderInfo.transferNo
})


//关于撤销 拒绝订单原因
const cancelOrderShow = ref(false);
const remark = ref("");
const diaTitle = ref("");
const cancelValue = ref("");
const cancelOrder = (type) => {
  // type 2撤销 3拒绝
  remark.value = "";
  cancelValue.value = type;
  type === 2 ? (diaTitle.value = "撤销原因") : (diaTitle.value = "拒绝原因");
  cancelOrderShow.value = true;
};

const chooseOrderStauts = ref("");
//打开标签明细
const showRfid = async (e, type) => {
  reactiveAssign(goodsInfo, e);
  if (!isOrderType.value) {
    goodsInfo.transferNo = orderInfo.transferNo;
    chooseOrderStauts.value = type;
    await getGoodsDetail(e);
  } else {
    if (type === 8) {
      goodsInfo.transferNo = orderInfo.thirdName;
    } else {
      goodsInfo.transferNo = orderInfo.transferNo;
    }
    chooseOrderStauts.value = type;
    await getGoodsDetail(e);
  }
};
// 获取产品详情
const getGoodsDetail = (e) => {
  productInfo({
    productId: e.productId,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(goodsInfo, res.data);
    rfidShow.value = true;
  });
};

//删除订单
const delOrder = () => {
  ElMessageBox.confirm("确认删除该订单！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    transferDeleteV2({
      transferNo: orderInfo.transferNo,
      type: orderType.value,
    }).then((res) => {
      if (res.code === 6999) {
        proxy.$message.error(res.msg);
        return
      }
      if (res.code !== 0 && res.code !== 6999) {
        ElMessageBox.confirm(res.msg, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "zh-message-box",
          showCancelButton: false,
        })
            .then(() => {
              emit("close");
            })
            .catch(() => {
              emit("close");
            });
        return;
      }
      proxy.$message.success("删除成功");
      emit("close");
    });
  });
};

const handleChat = () => {
  chatShow.value = true;
};
//处理订单
const upDateOrder = (type) => {
  let config = {
    2: "撤销",
    3: "拒绝",
    4: "同意",
  };
  let str = type === 4 ? "" : `确认${config[type]}该订单！`;
  if (type === 4) {
    if (isOrderType.value) {
      str = `${orderInfo.outStockName}调入产品至${orderInfo.inStockName}?`;
    } else {
      str = `${orderInfo.outStockName}调出产品至${orderInfo.inStockName}?`;
    }
  }
  if (type === 4) {
    ElMessageBox.confirm(str, "是否同意", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      customClass: "zh-message-box",
    }).then(() => {
      transferExamineV2({
        transferNo: orderInfo.transferNo,
        status: type,
        type: orderType.value,
        remark: remark.value,
      }).then((res) => {
        if (res.code === 6999) {
          proxy.$message.error(res.msg);
          return
        }
        if (res.code !== 0 && res.code !== 6999) {
          ElMessageBox.confirm(res.msg, "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            customClass: "zh-message-box",
            showCancelButton: false,
          }).then(() => {
            cancelOrderShow.value = false;
            emit("close");
          });
          return;
        }
        proxy.$message.success(`${config[type]}成功`);
        cancelOrderShow.value = false;
        openDetail();
      });
    });
  } else {
    transferExamineV2({
      transferNo: orderInfo.transferNo,
      status: type,
      type: orderType.value,
      remark: remark.value,
    }).then((res) => {
      if (res.code === 6999) {
        proxy.$message.error(res.msg);
        return
      }
      if (res.code !== 0 && res.code !== 6999) {
        ElMessageBox.confirm(res.msg, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "zh-message-box",
          showCancelButton: false,
        }).then(() => {
          cancelOrderShow.value = false;
          emit("close");
        });
        return;
      }
      if (type === 3) {
        proxy.$message.success(`${config[type]}成功`);
        cancelOrderShow.value = false;
        emit("close");
      } else {
        proxy.$message.success(`${config[type]}成功`);
        cancelOrderShow.value = false;
        openDetail();
      }
    });
  }
};

const orderInfo = reactive({
  transferNo: "",
  supplierName: "",
  productName: [],
  createTime: "",
  createUserName: "",
  remark: "",
  status: 0,
  active: false
});

const goodsNum = computed(() => {
  //8 出库，4入库
  let count = 0;
  let totalPrice = 0;
  tableData.value.forEach((item) => {
    count += Number(item.count) || 0;
    totalPrice +=
        Number(isOrderType.value ? item.inTotalPrice : item.outTotalPrice) ||
        0;
  });
  return {
    count: count,
    totalPrice: totalPrice.toFixed(2),
  };
});

const tableData = ref([]);

const openDetail = () => {
  reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
  orderType.value = orderInfo.orderType || route.params.orderType || 0
  transferDetailV2({
    transferNo: orderInfo.transferNo,
    type: orderType.value,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    proxy.$store.commit("PURCHASE_ORDER_INFO", res.data);
    reactiveAssign(orderInfo, res.data);
    getGoodsList();
    getChatList();
  });
  if (changeShow.value) {
    changeShow.value = false;
  }
};

//获取产品列表
const getGoodsList = () => {
  transferGoodsListV2({
    transferNo: orderInfo.transferNo,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data?.list || []
    tableData.value = resetGoodsList(list, (item) => {
      item.price = proxy.$filters.toYuan(item.price, "元");
      item.outTotalPrice = proxy.$filters.toYuan(item.outTotalPrice, "元");
      item.totalPrice = proxy.$filters.toYuan(item.totalPrice, "元");
    });
  });
};
//获取聊天列表
const getChatList = () => {
  orderMsg({
    transferNo: orderInfo.transferNo,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    chatList.value = res.data.list || [];
  });
};
const close = () => {
  proxy.$emit("close");
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  tableData.value = [];
};

const closeAll = () => {
  changeShow.value = false
  proxy.$emit("close");
  tableData.value = [];
}
const printHmtl = () => {
  let url = `/printAllot?orderSn=${orderInfo.transferNo}&orderType=${orderType.value}`
  window.open(url)
}
</script>

<script>
import RfidList from "@/components/rfidList/indexByOrder.vue";
import {reactive, toRefs, ref, watch, getCurrentInstance} from "vue";
import {reactiveAssign} from "@/utils/tool.js";
import {ArrowDown} from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";

export default {
  name: "oredrInfo",
  components: {
    ArrowDown,
    RfidList,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    .zh-message {
      height: 48px;
      line-height: 52px;
      background-color: #f7fafc;
      border-radius: 8px;
      position: relative;

      .mes-box {
        display: flex;
        align-items: center;
        padding: 0 50px 0 22px;
        box-sizing: border-box;
        font-size: 14px;
        flex-wrap: nowrap;

        .time {
          color: #aaaab2;
          margin-right: 20px;
        }

        .mes {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          flex: 1; //在可用的空间内100%填充
          overflow: hidden;
          text-overflow: ellipsis;
          //规定段落中的文本不进行换行
          white-space: nowrap;

          .ava {
            width: 24px;
            height: 24px;
            border-radius: 8px;
            margin-right: 6px;
            position: relative;
            top: -2px;
          }

          .nickName {
            color: #737480;
          }

          .mes-text {
            color: #161829;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .send-mes {
        width: 22px;
        height: 20px;
        overflow: hidden;
        position: absolute;
        top: 14px;
        right: 16px;
      }
    }

    .tabList {
      height: 420px;
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;

      .name {
        color: #73737f;
      }

      .text2 {
        color: $fsColor;
      }

      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;

        &.top1 {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }

      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }

    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;

      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }

      .remark_text {
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }

      .input {
        margin-top: 16px;
      }

      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;

        .info_btn {
          width: 112px;
        }

        .select {
          margin: 0 8px;
          width: 160px;
          height: 40px;

          :deep(.el-input) {
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

:deep(.el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}

.error-reason {
  .zh_content {
    height: 120px;

    .zh-input {
      padding: 16px;

      .textareaInput {
        height: 100px;
        background-color: #fafbfc;

        .el-textarea__inner {
          background-color: #fafbfc;
        }
      }
    }
  }
}
</style>
